import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  createStyles,
  Table,
  Anchor,
  ScrollArea,
  Grid,
  Button,
  Divider,
  ActionIcon,
  Pagination,
} from "@mantine/core";
import { Edit, Archive } from "tabler-icons-react";
import { ChannelProps } from "../../typing/channels";
import { api } from "../../api";
import { ChannelForm } from "./ChannelForm";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteChannel, getAllChannels } from "../../queries/channelQueries";

const useStyles = createStyles((theme) => ({
  progressBar: {
    "&:not(:first-of-type)": {
      borderLeft: `3px solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
      }`,
    },
  },
}));

const ChannelView = () => {
  const { classes, theme } = useStyles();
  const [isOpenForm, setOpenForm] = useState(false);
  const [updateData, setUpdateData] = useState<ChannelProps | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const queryClient = useQueryClient();
  let language = null;
  const { isLoading, data: channelData = [] } = useQuery(
    ["channels", language],
    getAllChannels,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const onUpdateData = (payload: ChannelProps) => {
    setUpdateData(payload);
    setOpenForm(true);
  };
  // const onDeleteChannel = async (id: number) => {
  //   const result = await api.delete(`channels/delete/${id}`);
  //   if (result.status) {
  //   }
  // };
  const { mutate: mutDeleteChannel } = useMutation(deleteChannel, {
    onSuccess: (newData) => {
      queryClient.invalidateQueries();
    },
  });
  const onRestForm = () => {
    setOpenForm(false);
    setUpdateData(null);
  };

  const totalPages = Math.ceil((channelData.data?.length ?? 0) / rowsPerPage);

  const rows =
    channelData.data &&
    channelData.data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row: any) => {
        return (
          <tr key={row.name}>
            <td>{row.name}</td>
            <td>{row.short_description}</td>
            <td>{row.long_description}</td>
            <td>{row.list_order}</td>
            <td>
              <Anchor<"a">
                size="sm"
                onClick={(event) => event.preventDefault()}
              >
                {row.thumbnail}
              </Anchor>
            </td>
            <td>
              <Anchor<"a">
                size="sm"
                onClick={(event) => event.preventDefault()}
              >
                {row.cover_image}
              </Anchor>
            </td>
            <td>{row.language}</td>
            <td>{row.number_of_posts}</td>
            <td>{row.number_of_followers}</td>
            <td>{row.share_text}</td>
            <td>{row.status}</td>
            <td>
              <Grid columns={2}>
                <Grid.Col span={1}>
                  <ActionIcon title="Update" onClick={() => onUpdateData(row)}>
                    <Edit size={25} strokeWidth={2} color={"green"} />
                  </ActionIcon>
                </Grid.Col>
                <Grid.Col span={1}>
                  <ActionIcon
                    title="Delete"
                    onClick={() => mutDeleteChannel(row.channel_id)}
                  >
                    <Archive size={25} strokeWidth={2} color={"red"} />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            </td>
          </tr>
        );
      });

  if (isLoading) {
    // TODO loader on data loading
    return null;
  }
  return (
    <>
      <Grid justify={"flex-end"}>
        <Grid.Col lg={1}>
          <Button
            variant="light"
            onClick={(e: SyntheticEvent) => setOpenForm(true)}
          >
            Create
          </Button>
        </Grid.Col>

        <Grid.Col>
          <Divider />
          <ScrollArea
            offsetScrollbars
            style={{ height: 600, padding: 15 }}
            scrollHideDelay={1000}
          >
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="xs"
              horizontalSpacing={"xl"}
            >
              <thead>
                <tr>
                  <th>Channel Name</th>
                  <th>Short Description</th>
                  <th>Long Description</th>
                  <th>List Order</th>
                  <th>Thumbnail</th>
                  <th>Cover Image</th>
                  <th>Language</th>
                  <th>No.of Posts</th>
                  <th>No.of Followers</th>
                  <th>Share Text</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
        <Grid.Col>
          <Pagination
            disabled={!channelData.data.length}
            position="right"
            page={page + 1}
            onChange={(page: number) => setPage(page - 1)}
            total={totalPages}
          />
        </Grid.Col>
      </Grid>
      <ChannelForm
        isOpenModal={isOpenForm}
        setOpenForm={setOpenForm}
        onReset={onRestForm}
        data={updateData}
      />
    </>
  );
};

export default ChannelView;
