import { Button, Grid, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createCollege, updateCollege } from "./queries";

export const Form = (props: any) => {
  const queryClient = useQueryClient();

  const { mutate: mutUpdateCollege } = useMutation(updateCollege, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });

  const { mutate: mutCreateCollege } = useMutation(createCollege, {
    onSuccess: (result) => {
      queryClient.invalidateQueries();
      props.onReset();
    },
  });
  useEffect(() => {
    if (props.data) setValues(props.data);
  }, [props.data]);

  const form = useForm({
    initialValues: {
      full_name: "",
      location: "",
      order_id: 0,
      short_name: "",
      college_id: "",
    } as CollegeProps,
    validate: {},
  });

  const { onSubmit, reset, errors, values, setFieldValue, setValues } = form;

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        reset();
        props.setOpenForm(false);
      }}
      title={values.id ? "Update College" : "Create College"}
      centered
      size={"xl"}
      closeOnClickOutside={false}
    >
      <form
        onSubmit={onSubmit((payload) => {
          if (payload.id) {
            mutUpdateCollege({ payload, id: payload.id });
          } else mutCreateCollege({ payload });
        })}
      >
        <Grid justify={"flex-start"}>
          <Grid.Col span={6}>
            <TextInput
              label="College Id"
              placeholder="college id"
              data-autofocus
              value={values.college_id}
              onChange={(event) =>
                setFieldValue("college_id", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Short Name"
              placeholder="college short name"
              data-autofocus
              value={values.short_name}
              onChange={(event) =>
                setFieldValue("short_name", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Full Name"
              placeholder="college full name"
              data-autofocus
              value={values.full_name}
              onChange={(event) =>
                setFieldValue("full_name", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Order"
              placeholder="order"
              data-autofocus
              type="number"
              value={values.order_id}
              onChange={(event) =>
                setFieldValue("order_id", parseInt(event.currentTarget.value))
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Location"
              placeholder="location"
              data-autofocus
              value={values.location}
              onChange={(event) =>
                setFieldValue("location", event.currentTarget.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={reset}
              mt="md"
              color={"red"}
              variant={"light"}
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
