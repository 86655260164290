import ChannelView from "../views/channels/Channels";
import CollegeView from "../views/college/view";
import CourseView from "../views/course/view";
import Feedbacks from "../views/feedback";
import FeedView from "../views/feeds/FeedView";
import HashtagsView from "../views/hashtags/View";
import { Landing } from "../views/Landing";
import NotesView from "../views/notes/notesView";
import SemesterView from "../views/semester/view";
import AppSetup from "../views/setup/AppSetupView";
import StreamView from "../views/stream/view";
import SubjectView from "../views/subjects/view";
import UniversityView from "../views/university/view";
import BlockedUsers from "../views/users/blockedUsers";
import RemovedUsers from "../views/users/removedUsers";
import UnregisteredUsers from "../views/users/unregisteredUsers";
import Users from "../views/users/users";

const MainRoutes = {
  path: "/",
  element: <Landing />,
  children: [
    {
      path: "/channels",
      element: <ChannelView />,
    },
    {
      path: "/feeds",
      element: <FeedView />,
    },
    {
      path: "/notes",
      element: <NotesView />,
    },
    {
      path: "/users",
      element: <Users />,
    },
    {
      path: "/registered",
      element: <Users />,
    },
    {
      path: "/incomplete",
      element: <UnregisteredUsers />,
    },
    {
      path: "/blocked",
      element: <BlockedUsers />,
    },
    {
      path: "/removed",
      element: <RemovedUsers />,
    },
    {
      path: "/appSetup",
      element: <AppSetup />,
    },
    {
      path: "/hashtags",
      element: <HashtagsView />,
    },
    {
      path: "/feedbacks",
      element: <Feedbacks />,
    },
    {
      path: "/college",
      element: <CollegeView />,
    },
    {
      path: "/courses",
      element: <CourseView />,
    },
    {
      path: "/streams",
      element: <StreamView />,
    },
    {
      path: "/semesters",
      element: <SemesterView />,
    },
    {
      path: "/subjects",
      element: <SubjectView />,
    },
    {
      path: "/university",
      element: <UniversityView />,
    },
  ],
};

export default MainRoutes;
